export const currencies = {
    nzd: 'nzd',
    aud: 'aud',
}

export const getStripeKey = (currency: string) => {
    if (currency === currencies.nzd) {
        console.log('Using the NZ stripe account');
        return process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_NZ; // Use NZ account key
    } 
    
    if(currency === currencies.aud) {
        console.log('Using the AU stripe account');
        return process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY; // Use AUD account key
    }
    console.log('Invalid currency');
    throw new Error("Invalid currency")
};